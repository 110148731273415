import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useState } from 'react';
import { useMutation } from 'urql';

import {
  Card,
  CardCloseButton,
  CardOptions,
  ErrorMessage,
  Form,
  SubmitButton,
  useModalContext,
} from '@/components';
import { useTranslate } from '@/contexts';
import { limitFragment } from '@/fragments/Limits';
import { LimitTypeEnum } from '@/globalTypes';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import {
  CancelPlayerSessionLimitMutation,
  CancelPlayerSessionLimitMutationVariables,
} from './__generated__/CancelPlayerSessionLimitMutation';
import { PlayerCancelSessionLimitFormStaticQuery } from './__generated__/PlayerCancelSessionLimitFormStaticQuery';

const query = graphql`
  query PlayerCancelSessionLimitFormStaticQuery {
    sanityPlayerCancelSessionLimitsForm {
      title {
        ...LocaleString
      }
      submit {
        ...LocaleString
      }
    }
  }
`;

const cancelPendingSessionLimitMutation = gql`
  mutation CancelPlayerSessionLimitMutation(
    $playerId: ID!
    $limitPeriodType: LimitTypeEnum!
  ) {
    cancelPendingSessionLimit(
      playerId: $playerId
      limitPeriodType: $limitPeriodType
    ) {
      id
      sessionLimit {
        dayLimit {
          ...LimitFragment
        }
        weekLimit {
          ...LimitFragment
        }
        monthLimit {
          ...LimitFragment
        }
      }
    }
  }
  ${limitFragment}
`;

const PlayerCancelSessionLimitForm: FC<{
  playerId: string;
  limitPeriodType: LimitTypeEnum;
}> = ({ playerId, limitPeriodType }) => {
  const staticData = useStaticQuery<PlayerCancelSessionLimitFormStaticQuery>(
    query,
  );

  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const isMounted = useIsMounted();

  const form = staticData.sanityPlayerCancelSessionLimitsForm;

  const [
    cancelPendingSessionLimitState,
    cancelPendingSessionLimit,
  ] = useMutation<
    CancelPlayerSessionLimitMutation,
    CancelPlayerSessionLimitMutationVariables
  >(cancelPendingSessionLimitMutation);

  const defaultValues = {};

  const { close } = useModalContext();

  const { t } = useTranslate();

  const onSubmit = (_values: typeof defaultValues) => {
    if (playerId) {
      cancelPendingSessionLimit({
        playerId,
        limitPeriodType,
      }).then((res) => {
        if (res.error?.message && isMounted) {
          setErrorMessage(res.error?.message);
        } else if (close) {
          close();
        }
      });
    }
  };

  if (!form || !defaultValues) {
    return null;
  }
  return (
    <Card
      size="lg"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="p-3">
        <Form
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          className="grid grid-cols-2 sm:grid-cols-3 gap-6"
        >
          <ErrorMessage message={errorMessage} />
          <SubmitButton
            value={t(form.submit)}
            disabled={cancelPendingSessionLimitState.fetching}
          />
        </Form>
      </div>
    </Card>
  );
};

export default PlayerCancelSessionLimitForm;
